.MainApp {
    padding: 1rem 2rem;
    background: #fafafa;
}


.Statistics{
    .Polaris-Layout{
        .Polaris-Layout__Section{
            max-width: calc(25% - 2rem);
            min-width: 23%;
        }
    }
}
