.ListUserTable {
    table {
        table-layout: fixed;

        th {
            width: percentage(.55 / 6);
            overflow: hidden;

            &:last-child {
                width: 20%;
            }

            &:first-child {
                width: 25%;
            }
        }
    }
}
