.ManageJobs {
    .HeadingTab {
        ul {
            padding: 0;
            display: flex;
            align-items: center;
            margin: 0 0 8px;

            li {
                padding: 10px 25px;
                border: 1px solid #dddddd;
                margin-right: 5px;
                font-size: 1.4rem;
                font-weight: 500;
                border-radius: 6px;

                &.Active, &:hover {
                    cursor: pointer;
                    background: #202e78;
                    color: #ffffff;
                }
            }
        }
    }

    .ContentTab {
        .ListProxy {
            .ant-pagination {
                li {
                    &:hover {
                        .ant-pagination-item-container {
                            line-height: 23px;
                        }
                    }

                    button {
                        line-height: 0;
                    }
                }
            }
        }
    }
}

.BandWidth {
    .Filter {
        text-align: right;

        label {
            font-size: 1.4rem;
            font-weight: 500;
            margin-right: 10px;
        }

        .ant-picker.ant-picker-range {
            height: 40px;
            border-radius: 6px;
        }
    }

    .CostContent {
        display: inline-block;
        padding: 15px 25px;
        border: 1px solid #202e78;
        background: #202e78;
        border-radius: 6px;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }

        .Title {
            h3 {
                color: #ffffff;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 22px;
                margin-bottom: 8px;
            }

            .Content {
                span {
                    color: #ffffff;
                    font-size: 32px;
                    font-weight: bold;
                }
            }
        }
    }
}

.ListProxy {
    .ContentList {
        .TopContent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;

            .Totals {
                span {
                    font-weight: bold;
                }
            }

            .ListFilter {
                display: flex;
                align-items: center;
            }

            .Filters {
                margin-right: 24px;
                display: flex;
                align-items: center;
                label {
                    font-size: 1.4rem;
                    font-weight: 500;
                    margin-right: 10px;
                }

                .ant-select-selector {
                    width: 100px;
                }
            }
        }

        .table {
            width: 100%;

            thead {
                tr {
                    th {
                        padding: 16px;
                        border-top: 1px solid #eeeeee;
                        border-bottom: 1px solid #eeeeee;
                        text-align: left;
                        background: #fafafa;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 16px;
                        border-bottom: 1px solid #eeeeee;
                        text-align: left;

                        &.Status {
                            &.Deactivate {
                                color: #b7791f;
                            }

                            &.Active {
                                color: #2f855a;
                            }
                        }

                        &.Country {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .ant-pagination {
            margin-top: 24px;
        }
    }
}
