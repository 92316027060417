
.WebhookSettingContainer {
  .column-response-status {
    display: flex;
    align-items: center;
    > span {
      margin-right: 0;
    }
  }
  .Polaris-DataTable__Cell {
    white-space: unset;
  }
  .Polaris-Header-Title__TitleAndSubtitleWrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .Polaris-Header-Title__SubTitle {
    > p {
      margin-bottom: 5px;
    }
  }
  .text-primary, a {
    color: #0088ff;
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
  .ant-picker-range-separator {
    position: relative;
    &:after {
      content: "-";
      position: absolute;
      top: 1px;
      right: 8px;
      color: #cccccc;
    }
    svg {
      display: none;
    }
  }
  //.Polaris-Icon {
  //  height: 15px;
  //  width: 15px;
  //}
  .Polaris-Button {
    min-width: auto;
    min-height: auto;
    margin-right: 10px;

    &#DeleteButton, &#CancelButton {
      svg {
        fill: #e3381a;
      }
    }

    &#ViewPayload {
      border: none;
      background: none;
      box-shadow: none;

      svg {
        fill: #0088ff;
      }
    }
  }

  .ant-tag {
    border-radius: 13px;
    line-height: 19px;
  }
}

.PayloadExampleModal {
  .payload {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    .headerPayload, .bodyPayload {
      width: 50%;
    }
  }
}