.DashboardHeader {
    border-bottom: 1px solid #ccc;
    padding: .25rem 2rem;

    .Logo {
        padding: .75rem;
        color: #15cd72;
        border-radius: .25rem;
        transition: all .2s linear;
        font-weight: bold;
        text-decoration: none;

        &:hover {
            background: #15cd72;
            color: #fff;
        }
    }
}
