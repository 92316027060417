.DateLimitFilter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .LimitControl {
        border-right: 1px solid #ccc;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        select {
            width: 10rem;
        }

        label {
            margin: 0 1rem;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .DateControl {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
            margin: 0;
        }
    }
}
