.Dashboard {
    .DashboardContainer {
        .Sidebar {
            width: 15vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            overflow: auto;
            border-right: 1px solid #ccc;
        }

        .MainAppContainer {
            width: 100%;
            padding-left: 15vw;
        }
    }
}
