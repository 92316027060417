.DashboardSidebar {
    .Menus {
        display: flex;
        flex-wrap: wrap;

        .Menu {
            padding: 1rem;
            width: 100%;
            transition: all .2s linear;
            color: unset;
            text-decoration: none;

            &:hover {
                background: #15cd72;
                color: #fff;
            }
        }
    }
}
