.TrackingNumbers {
    .Toolbar {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 1rem;
    }
    a.website {
        color: #2b6cb0;
        font-weight: bold;
    }
}
