.TrackingMapping{ 
    .TrackingFilter{
        display: flex;
        justify-content:space-between;
        .TrackingSearch{
            width:30%
        }
        .ListFilter{
            display: flex;
            flex-wrap: nowrap;
        }
        .Filters {
            margin-left: 24px;

            &.ml-2 {
                margin-left: 10px;
            }

            label {
                font-size: 1.4rem;
                font-weight: 500;
                margin-right: 10px;
            }

            .ant-select-selector {
                min-width: 200px;
                max-width: 500px;
            }
        }
    }
    .table {
        width: 100%;

        thead {
            tr {
                th {
                    padding: 16px;
                    border-top: 1px solid #eeeeee;
                    border-bottom: 1px solid #eeeeee;
                    text-align: left;
                    background: #fafafa;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 16px;
                    text-align: left;
                    &.Status {
                        &.Deactivate {
                            color: #b7791f;
                        }

                        &.Active {
                            color: #2f855a;
                        }
                    }

                    &.Country {
                        text-transform: uppercase;
                    }
                    .ant-input{
                        text-overflow: ellipsis;
                        &:focus{
                            box-shadow: none !important;
                            border-right-width: none !important;
                        }
                    }
                    &:nth-child(1){
                        max-width: 545px;
                    }
                }
                .acions{
                    display:flex;
                    justify-content:space-around;
                    width:60px;
                    #DeleteButton{
                        margin-left: 5px;
                        .Polaris-Icon__Svg{
                            fill: #e3381a !important;
                            opacity: 0.7;
                            transition: all .1s;
                        }
                        &:hover .Polaris-Icon__Svg{
                            opacity: 1;
                        }
                    }
                }
            }
        }
        
    }

    &.TrackingMappingV2{
        .TrackingFilter{
            justify-content: flex-start;
            
            .TrackingSearch{
                width: auto;
            }
        }
    }
}
.TrackingMappingCreate{
    .CreateChild{
        margin-bottom: 15px;
        label{
            display: inline-block;
            margin-bottom: 4px;
            &::after{
                content: "*";
                color: red;
                margin-left: 5px;
            }
        }
    }
    .invalid-feedback{
        color: #ed4122
    }
    .OriginalNote{
        white-space:nowrap;
    }
}
.FilterChild{
    border-bottom: 1px solid #e2e8f0 ;
    padding-bottom: 15px;
    margin-bottom: 10px;
}
.CheckedList{
    h3{
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .ListItem{
        overflow:auto;
        height:177px; 
        width:100%;
        ul li span small{
            margin: 0 36px 0 0;
            float: right;
        }
        .Error{
            color: red;
        }
        .Success{
            color: green;
        }
    }
}
.ant-pagination .anticon {
    display: block !important;
}
.ant-select-dropdown-empty {
     display: none !important;
}
.ant-modal-content{
    border-radius: 4px !important;
    .ant-btn-primary {
        padding: 4px 26px !important;
        outline: 1px solid #1890ff;
        border-color: white !important;
        background: #1a73e8 !important;
        &:focus{
            padding: 4px 26px !important;
            outline: 1px solid #1890ff;
            border-color: white !important;
        }
    }
    .ant-btn{
        border-radius: 4px !important;
    }
}